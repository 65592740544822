import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Suspense, lazy, useEffect } from "react";
import { ROUTES } from "routes";

import {
  LoginPhoneNumberState,
  LoginUserState,
  TriggerUserApi,
  darkThemeState,
  userDetailsState,
  userLockedState,
  userPersonalDetails,
} from "states";
import { useCookie, useNetwork, useNotification } from "hooks";

import { APIS } from "constant";
import { Loader } from "components";
import StaticFileServe from "views/staticFileServeComponent/staticFileServe";
import ComingSoon from "@views/SignIn/components/coming-soon/coming-soon";
import { Layout } from "@components/Layout";
import { TermsOfService } from "@views/SignIn/components/terms-of-service";
import { PrivacyPolicy } from "@views/SignIn/components/privacy-policy";
import { BiometricPolicy } from "@views/SignIn/components/biometric-policy";
import { AccessDenied } from "views";
import { EmailVerify } from "@components/EmailVerification";
import { MpcSetup } from "@views/SignIn/components/mpc-wallet/mpc-setup";
import MpcQr from "@views/SignIn/components/mpc-wallet/mpc-qr";
import MpcSetupStatus from "@views/SignIn/components/mpc-wallet/mpc-setup-status";
import { ContactUs } from "@views/SignIn/components/contact-us";
import MpcMobileRedirect from "@views/SignIn/components/mpc-wallet/mpc-mobile-redirect";


const lazyRetry = function(componentImport:any) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    componentImport().then((component:any) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      }).catch((error:any) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          return window.location.reload();
        }
        reject(error);
      });
  });
};

const Exchange = lazy(
  () => lazyRetry(() => import("../views/exchange/exchange")) as any
);

const SignInForm = lazy(
  () => lazyRetry(() => import("../views/SignIn/components/signin-form/signin-form")) as any
);

const ChooseMethod = lazy(
  () => lazyRetry(() => import("../views/SignIn/components/choose-method/choose-method")) as any
);

const OtpVerify = lazy(
  () => lazyRetry(() => import("../views/SignIn/components/Form/otp-verify")) as any
);

const MobileLinkSent = lazy(
  () => lazyRetry(() => import("../views/SignIn/components/mobile-link-sent/mobile-link-sent")) as any
);


const  BiometricApprovalSent  = lazy(
  () => lazyRetry(() => import("../views/SignIn/components/biometric-approval-sent/biometric-approval-sent")) as any
  
); 
const NewUserOtpVerify = lazy(
  () => lazyRetry(() => import("../views/SignIn/components/newUserOtpVerify/new-user-otp-verify")) as any
);


const LoginRoutes = lazy(
  () => lazyRetry(() => import("../views/SignIn/loginRoutes")) as any
);

const Account = lazy(
  () => lazyRetry(() => import("../views/account/AccountOverview")) as any
);

const AdminDashboard = lazy(
  () => lazyRetry(() => import("../views/admin-dashboard/admin-dashboard")) as any
);

const News = lazy(() => lazyRetry(() => import("../views/news/news")) as any);

const MyPortfolioRoutes = lazy(
  () => lazyRetry(() => import("../views/MyPortfolio/MyPortfolioRoutes")) as any
);
const Reports = lazy(
  () => lazyRetry(() => import("../views/report/reports")) as any
);
const MintApp = lazy(
  () => lazyRetry(() => import("../views/mint/mint-app")) as any
);
const AuctionConsumer = lazy(
  () => lazyRetry(() => import("../views/AuctionConsumer/AuctionConsumer")) as any
);
const UserBidDetails = lazy(
  () =>
    lazyRetry(
      () => import("../views/AuctionConsumer/UserBidDetails/UserBidDetails")
    ) as any
);
const UserBidAuctions = lazy(
  () =>
    lazyRetry(
      () => import("../views/AuctionConsumer/UserBidAuctions/UserBidAuctions")
    ) as any
);
const AuctionDetails = lazy(
  () =>
    lazyRetry(
      () => import("../views/AuctionConsumer/AuctionDetails/AuctionDetails")
    ) as any
);
const RemovalProcess = lazy(
  () =>
    lazyRetry(
      () =>
        import(
          "../views/account/components/AuthorisedUser/components/removalProcess/removalProcess"
        )
    ) as any
);
const Invitation = lazy(
  () =>
    lazyRetry(
      () =>
        import(
          "../views/account/components/AuthorisedUser/components/invitation/invitation"
        )
    ) as any
);
const RemovalRequest = lazy(
  () =>
    lazyRetry(
      () =>
        import(
          "../views/account/components/AuthorisedUser/components/removalRequest/removalRequest"
        )
    ) as any
);
const RequestCancel = lazy(
  () =>
    lazyRetry(
      () =>
        import(
          "../views/account/components/AuthorisedUser/components/removalRequest/requestCancel"
        )
    ) as any
);
const MobileApprove = lazy(
  () =>
    lazyRetry(
      () => import("../views/SignIn/components/mobile-approve/mobile-approve")
    ) as any
);
const MyOrders = lazy(
  () => lazyRetry(() => 
  import("../views/MyOrders/MyOrders")) as any
);
const OrderDetails = lazy(
  () => lazyRetry(() => import("../views/MyOrders/components/order-details")) as any
);

export const AllRoutes = () => {
  const {
    EXCHANGE,
    PROFILE,
    LOGIN,
    SIGNINFORM,
    CHOOSEMETHOD,
    MOBILELINKSENT,
    BIOMETRICAPPROVALSENT,
    NEWUSEROTPVERIFY,
    OTPVERIFY,
    REPORTS,
    NO_ROUTE,
    WALLET,
    ADMIN,
    NEWS,
    ORDERSPAGE,
    ORDERDETAILS,
    // FULLNEWS,
    AUCTION,
    AUCTION_DETAILS,
    USER_BID_LIST,
    USER_BID_DETAILS,
    COOWNERS,
    COOWNERSREMOVE,
    MOBILE_VERIFICATION,
    COOWNERSINVITE,
    COOWNERSREMOVALREQUEST,
    COOWNERSREMOVALREQUESTCANCEL,
    COMINGSOON,
    TERMS_SERVICE,
    PRIVACY_POLICY,
    BIOMETRIC_POLICY,
    CONTACT_US,
    ACCESS_DENIED,
    PORTFOLIO_ROUTE,
    WALLET_ROUTE,
    EMAILVERIFY_DENIED,
    MPC_SETUP,
    MPC_QR,
    MPC_SETUP_STATUS,
    MPC_MOBILE_REDIRECT,
  } = ROUTES;
  const setUserInfo = useSetRecoilState(LoginUserState);
  const userLock = useRecoilValue(userLockedState);
  const triggerUserApi = useRecoilValue(TriggerUserApi);

  const { get } = useCookie();

  const userDetails = get("userDetails") ?? {};
  const themeMode = localStorage.getItem("darkMode");
  const { isAdmin, phone, countryCode } = useRecoilValue(userPersonalDetails);
  const setLoginDetailUser = useSetRecoilState(LoginPhoneNumberState);
  const setUserDetail = useSetRecoilState(userDetailsState);

  const { deleteCookie } = useCookie();

  const { get: getUser, data: userData, isLoaded: userLoaded } = useNetwork();

  const { set: setCookieToken } = useCookie();
  const { put: loginInvite } = useNetwork();
  const { successNotification } = useNotification();
  const location = useLocation();
  const { errorNotification } = useNotification();
  const setThemeDark = useSetRecoilState(darkThemeState);
  const { get: getCookieData } = useCookie();

  useEffect(() => {
    const userDetails = getCookieData("userDetails");
    if (userDetails?.token) {
      getUser(APIS.Users);
    }
  }, [getCookieData, getUser, triggerUserApi]);

  useEffect(() => {
    if (userData?.data) {
      const ownerId = userData?.data?.coOwnerId;
      setUserDetail(userData);
      localStorage.setItem("coOwnerId", ownerId);
    }
  }, [setUserDetail, userData, userData?.data]);

  useEffect(() => {
    if (phone && countryCode) {
      setLoginDetailUser({
        phone: phone,
        countryCode: countryCode,
      });
    }
  }, [countryCode, phone, setLoginDetailUser]);

  useEffect(() => {
    const userData = get("userDetails");
    if (userData?.isLoggedIn) {
      setUserInfo({
        phone: "",
        token: userData.token,
        isLoggedIn: userData.isLoggedIn,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    themeMode === null && localStorage.setItem("darkMode", "true");
  }, [themeMode]);

  useEffect(() => {
    if (location?.pathname.includes("/co-owners-email-accept/")) {
      const urlId = location.pathname.split("/");
      const inviteId = urlId[urlId.length - 1];

      loginInvite(`${APIS.COOWNER}/${inviteId}`).then((res) => {
        if (res?.token) {
          const registrations = JSON.parse(
            localStorage.exchageAppRegistration || "[]"
          );
          themeMode === "true" ? setThemeDark(true) : setThemeDark(false);
          // localStorage.clear();
          deleteCookie("userDetails");
          localStorage.setItem(
            "exchageAppRegistration",
            JSON.stringify(registrations)
          );
          const loginDetails = {
            token: res?.token,
            phone: "",
            isLoggedIn: true,
          };
          setCookieToken("userDetails", loginDetails);
          setUserInfo(loginDetails);
          successNotification(res?.message);
        } else {
          errorNotification(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/.well-known/apple-app-site-association"
          element={<StaticFileServe />}
        />
        <Route path={MOBILE_VERIFICATION} element={<MobileApprove />} />
        <Route path={EMAILVERIFY_DENIED} element={<EmailVerify  />} />
        <Route path={CONTACT_US} element={<ContactUs />} />
         
        {!userDetails?.token ? (
          <>
            <Route path={LOGIN} element={<SignInForm />} />
            <Route path={CHOOSEMETHOD} element={<ChooseMethod />} />
            <Route path={OTPVERIFY} element={< OtpVerify/>} />
            <Route path={MOBILELINKSENT} element={<MobileLinkSent/>} />
            <Route path={BIOMETRICAPPROVALSENT} element={<BiometricApprovalSent />} />
            <Route path={NEWUSEROTPVERIFY} element={<NewUserOtpVerify />} />
            <Route path={COMINGSOON} element={<ComingSoon />} />
            <Route path={COOWNERS} element={<Navigate to={EXCHANGE} />} />
            <Route path={COOWNERSREMOVE} element={<RemovalProcess />} />
            <Route path={TERMS_SERVICE} element={<TermsOfService />} />
            <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={BIOMETRIC_POLICY} element={<BiometricPolicy />} />
            {/* <Route path={MPC_SETUP} element={<MpcSetup />} /> */}
            <Route path={MPC_QR} element={<MpcQr />} />
            <Route path={MPC_SETUP_STATUS} element={<MpcSetupStatus />} />
            <Route path={MPC_MOBILE_REDIRECT} element={<MpcMobileRedirect />} />
            <Route path="*" element={<Navigate to={COMINGSOON} />} />
          </>
        ) : (
          <>
            {userLoaded ? (
              <>
                {userLock ? (
                  <>
                    <Route path={ACCESS_DENIED} element={<AccessDenied />} />
                    <Route
                      path="/*"
                      element={<Navigate to={ACCESS_DENIED} />}
                    />
                  </>
                ) : (
                  <>
                    <Route
                      path={`/*`}
                      element={
                        <Layout>
                          <Suspense fallback={<Loader />}>
                            <Routes>
                              <Route
                                path={`${EXCHANGE}/:id?`}
                                element={<Exchange />}
                              />
                              {/* <Route path={NEWS} element={<News />} /> */}
                              <Route path={ORDERSPAGE} element={<MyOrders />} />
                              <Route
                                path={ORDERDETAILS}
                                element={<OrderDetails />}
                              />
                              <Route
                                path={ WALLET_ROUTE}
                                element={<MyPortfolioRoutes />}
                              />
                              <Route
                                path={AUCTION}
                                element={<AuctionConsumer />}
                              />
                              <Route
                                path={AUCTION_DETAILS}
                                element={<AuctionDetails />}
                              />
                              <Route
                                path={USER_BID_LIST}
                                element={<UserBidAuctions />}
                              />
                              <Route
                                path={USER_BID_DETAILS}
                                element={<UserBidDetails />}
                              />
                              <Route path={REPORTS} element={<Reports />} />
                              <Route path={PROFILE} element={<Account />} />
                              {/*{!!isAdmin && (
                                <Route
                                  path={ASSETS}
                                  element={<Navigate to={"/admin/minted/assets"} />}
                                />
                              )}*/}
                              <Route
                                path={ADMIN}
                                element={
                                  isAdmin ? (
                                    <AdminDashboard />
                                  ) : (
                                    <Navigate to={EXCHANGE} />
                                  )
                                }
                              />
                              <Route
                                path="*"
                                element={<Navigate to={EXCHANGE} />}
                              />
                            </Routes>
                          </Suspense>
                        </Layout>
                      }
                    />
                    <Route
                      path={COOWNERS}
                      element={<Navigate to={EXCHANGE} />}
                    />
                    <Route path={COOWNERSREMOVE} element={<RemovalProcess />} />
                    <Route path={COOWNERSINVITE} element={<Invitation />} />
                    <Route
                      path={COOWNERSREMOVALREQUEST}
                      element={<RemovalRequest />}
                    />
                    <Route
                      path={COOWNERSREMOVALREQUESTCANCEL}
                      element={<RequestCancel />}
                    />
                    <Route path="*" element={<Navigate to={EXCHANGE} />} />
                  </>
                )}
              </>
            ) : (
              <Route path="*" element={<Loader />} />
            )}
          </>
        )}
      </Routes>
    </Suspense>
  );
};
